import { useEffect } from 'react';

/**
 * Calls the onFocusable callback when second parameter is passed to true
 * @param onFocusable
 * @param focusable
 */
export function useOnFocusable(onFocusable?: () => void, focusable: boolean = true): void {
  useEffect(() => {
    if (focusable && onFocusable) {
      onFocusable();
    }
  }, [focusable, onFocusable]);
}
