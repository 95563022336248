import { Ratio, isRatio } from '@canalplus/mycanal-commons';
import type { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import { hashObject } from '@canalplus/mycanal-util-react';
import { TitleDisplayMode, type PersoLists } from '@canalplus/sdk-hodor';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import { memo, useMemo } from 'react';
import { StrateMode } from '../../../constants/strates';
import I18n from '../../../lang';
import type { ContentStrateV5, DisplayParameters } from '../../../templates/LandingV5/data/formatter';
import { OnFocusableProbe } from '../../../templates/OnFocusableProbe/OnFocusableProbe';
import Linker from '../../Linker/Linker';
import { ContentRowTemplateItemLiveTv } from '../ContentRowTemplateItem/ContentRowTemplateItemLiveTv/ContentRowTemplateItemLiveTv';
import { ContentRowTemplateItemStandard } from '../ContentRowTemplateItem/ContentRowTemplateItemStandard/ContentRowTemplateItemStandard';
import LoadableContentRow from '../LoadableContentRow';

export enum ContentsDisplayMode {
  Headline = 'headline',
  Top10 = 'top',
}

export type ContentRowTemplateProps = {
  contents?: (ContentStrateV5 | ApiV2BroadcastChannel)[];
  displayParameters?: DisplayParameters;
  fetchNextContents?: (nbItemFetched: number) => void;
  header?: ContentRowHeader;
  isFromDetail?: boolean;
  isFromLanding?: boolean;
  isFromShowcase?: boolean;
  isImmersive?: boolean;
  isRemovableItem?: boolean;
  isTvDevice?: boolean;
  itemOffset?: number;
  listType?: PersoLists;
  onClickRemove?: (event: React.MouseEvent, contentID: string, listType: PersoLists) => void;
  strateMode?: StrateMode;
  onFocusable?: () => void;
};

function ContentRowTemplate({
  contents,
  displayParameters: {
    contentsDisplayMode = '',
    imageRatio = Ratio.Ratio169,
    imageSize = 'normal',
    titleDisplayMode = TitleDisplayMode.All,
    hidePlaceholders = false,
  } = {},
  fetchNextContents,
  header,
  isFromDetail = false,
  isFromLanding = false,
  isFromShowcase = false,
  isImmersive,
  isRemovableItem,
  isTvDevice = false,
  itemOffset,
  listType,
  onClickRemove,
  strateMode = StrateMode.Standard,
  onFocusable,
}: ContentRowTemplateProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const ratio = isRatio(imageRatio) ? imageRatio : Ratio.Ratio169;
  const showControls = !isTvDevice && Boolean(contents?.length);

  const contentsToRender = useMemo(() => {
    const placeholders = [...(Array(10) as null[])];

    return contents?.length ? contents : placeholders;
  }, [contents]);

  const labels = useMemo(
    () => ({
      next: t('HorizontalPaging.next'),
      previous: t('HorizontalPaging.previous'),
    }),
    [t]
  );

  const isTop10 = contentsDisplayMode === ContentsDisplayMode.Top10;

  const children = useMemo(() => {
    const tempTable = [];
    return contentsToRender.map((content, index) => {
      const idKey = hashObject({ ...content, index }, ['hash', 'index'], tempTable).hash;
      const isHeadline = contentsDisplayMode === ContentsDisplayMode.Headline;

      if (strateMode === StrateMode.LiveTv) {
        return (
          <ContentRowTemplateItemLiveTv
            key={idKey}
            channel={content}
            ratio={ratio}
            isHeadline={isHeadline}
            imageSize={imageSize}
            titleDisplayMode={titleDisplayMode}
          />
        );
      }

      return (
        <ContentRowTemplateItemStandard
          key={idKey}
          content={content}
          ratio={ratio}
          imageSize={imageSize}
          isBackgroundTransparent={hidePlaceholders}
          isFromLanding={isFromLanding}
          isFromDetail={isFromDetail}
          isRemovableItem={isRemovableItem}
          listType={listType}
          onClickRemove={onClickRemove}
          strateMode={strateMode}
          titleDisplayMode={titleDisplayMode}
          isTop10={isTop10}
        />
      );
    });
  }, [
    contentsDisplayMode,
    contentsToRender,
    imageSize,
    hidePlaceholders,
    isFromDetail,
    isFromLanding,
    isRemovableItem,
    listType,
    onClickRemove,
    ratio,
    strateMode,
    titleDisplayMode,
    isTop10,
  ]);

  // Workaround
  // We ensure by this push call that OnFocusableProbe component is next to other children to avoid
  // some regression (see comment inside OnFocusableProbe component file) and typing error
  // ContentRow need a list of ReactElement as children
  const childrenToRender = contents?.length
    ? [
        ...children,
        <OnFocusableProbe key="OnFocusableProbe" onFocusable={onFocusable} focusable={!!contents?.length} />,
      ]
    : children;

  return (
    <LoadableContentRow
      defaultItemOffset={itemOffset}
      getNextContent={fetchNextContents}
      header={header}
      imageSize={imageSize}
      isFromDetail={isFromDetail}
      isFromImmersive={isImmersive}
      isFromShowcase={isFromShowcase}
      isOrderedList={isTop10}
      isTvDevice={isTvDevice}
      labels={labels}
      Linker={Linker}
      ratio={ratio}
      scrollDuration={isTvDevice ? 0 : 200}
      showControls={showControls}
      titleDisplayMode={titleDisplayMode}
    >
      {childrenToRender}
    </LoadableContentRow>
  );
}

export default memo(ContentRowTemplate);
