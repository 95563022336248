import { IContent } from '@canalplus/mycanal-sdk';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import { useSelector } from 'react-redux';
import IconStartOver from '../../assets/svg/startover.svg';
import { StrateMode } from '../../constants/strates';
import { TemplateTypes } from '../../constants/templateTypes';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { formatLiveGridPlayerData } from '../../helpers/liveTV/liveTV-helper';
import I18n from '../../lang';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { launchPlayerFullScreen } from '../../store/slices/player-thunk';
import { isStartOverAuthorizedByEpgIDSelector } from '../../store/slices/user-selectors';
import Button from '../Button/Button';
import styles from './StartOverButton.css';

export type StartOverButtonProps = {
  content: ApiV2BroadcastChannel | IContent;
  context?: ApiV2Context;
  customHandler?: () => void;
  title?: string;
};

function StartOverButton({ content, context, customHandler, title = '' }: StartOverButtonProps): JSX.Element | null {
  const { t } = I18n.useTranslation();
  const dispatch = useAppDispatch();

  const isTvDevice = useSelector(displayTVModeSelector);
  const isStartOverAuthorizedByEpgID = useSelector(isStartOverAuthorizedByEpgIDSelector);

  const { epgID } = content;
  const isStartOverAuthorized = Boolean(epgID && isStartOverAuthorizedByEpgID[epgID]);

  if (isTvDevice || !isStartOverAuthorized) {
    return null;
  }

  const startOverTitle = `${t('StartOver.seeFromBeginning')} ${title}`;

  const buttonHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (customHandler) {
      customHandler();
    } else {
      event.persist();
      const buttonData = formatLiveGridPlayerData(content, context, true) || {};
      dispatch(launchPlayerFullScreen({ event, data: buttonData, type: StrateMode.LiveTv }));
    }
  };

  return (
    <Button
      className={styles.startOverButton__button}
      color={TemplateTypes.DEFAULT}
      handler={buttonHandler}
      icon={<IconStartOver className={styles.startOverButton__icon} />}
      ariaLabel={startOverTitle}
      title={startOverTitle}
    />
  );
}

export default StartOverButton;
