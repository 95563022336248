import { cva } from 'class-variance-authority';

export const buttonLogoWrapperCVA = cva(['aspect-w-4 aspect-h-3', 'box-border border-none flex', 'min-w-full'], {
  variants: {
    device: {
      web: 'rounded-dt-radius-medium',
      tv: 'rounded-dt-radius-xlarge',
    },
    wrapper: {
      button: 'cursor-pointer pt-dt-spacing-none',
      div: undefined,
    },
    variant: {
      solid: undefined,
      ghost: 'bg-transparent',
    },
  },
  compoundVariants: [
    {
      variant: 'solid',
      device: 'web',
      className: 'bg-dt-theme-background-button-logo-button-logo',
    },
    {
      variant: 'solid',
      device: 'tv',
      className: 'bg-dt-theme-tv-background-button-logo-button-logo',
    },
    {
      device: 'tv',
      wrapper: 'button',
      className:
        'tv-focus-self:outline tv-focus-self:ring-fallback-4 tv-focus-self:outline-none tv-focus-self:scale-104 outline-none',
    },
    {
      device: 'tv',
      wrapper: 'div',
      className:
        'tv-focus-parent:outline tv-focus-parent:ring-fallback-4 tv-focus-parent:outline-none tv-focus-parent:scale-104',
    },
  ],
});
