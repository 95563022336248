import { ButtonLogo } from '@canalplus/dive';
import { DIMENSIONS, ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { type CardProps } from '@canalplus/mycanal-sharedcomponent';
import { PersoLists, TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StrateMode } from '../../../../constants/strates';
import { getPublicConfig } from '../../../../helpers/config/config-helper';
import { getHodorSticker } from '../../../../helpers/stickers/stickers-helper';
import {
  featUpcomingHodorStickerSelector,
  isDarkModeSelector,
} from '../../../../selectors/application/application-selectors';
import { isImmersiveSelector } from '../../../../store/slices/immersive-selectors';
import { ContentStrateV5 } from '../../../../templates/LandingV5/data/formatter';
import CardConnected, { CardConnectedProps } from '../../../Card/CardConnected';
import Linker from '../../../Linker/Linker';
import type { LinkerData } from '../../../Linker/types';
import ThumborMediaImage from '../../../ThumborMediaImage/ThumborMediaImage';
import styles from '../ContentRowTemplateItem.css';
import { RankItemStandard } from './RankItemStandard';

const cx = classNames.bind(styles);

export type ContentRowTemplateItemStandardProps = {
  content: ContentStrateV5;
  imageSize?: ImageSize;
  isBackgroundTransparent?: boolean;
  isFromDetail?: boolean;
  isFromLanding?: boolean;
  isRemovableItem?: boolean;
  listType?: PersoLists;
  onClickRemove?: (event: React.MouseEvent, contentID: string, listType: PersoLists) => void;
  ratio: Ratio;
  strateMode?: StrateMode;
  titleDisplayMode?: TitleDisplayMode;
  isTop10?: boolean;
};

export function ContentRowTemplateItemStandard({
  content,
  strateMode = StrateMode.Standard,
  titleDisplayMode = TitleDisplayMode.All,
  ratio,
  isFromDetail,
  isFromLanding,
  imageSize = 'normal',
  isBackgroundTransparent = false,
  isRemovableItem = false,
  isTop10 = false,
  listType,
  onClickRemove,
}: ContentRowTemplateItemStandardProps): JSX.Element | null {
  const isFeatUpcomingHodorSticker = useSelector(featUpcomingHodorStickerSelector);
  const isFromImmersive = useSelector(isImmersiveSelector) && !isFromLanding;
  const isDark = useSelector(isDarkModeSelector);

  const linkerData = useMemo(
    () =>
      ({
        contentID: content?.contentID,
        mainOnClick: content?.onClick,
        context: content?.context,
      }) satisfies LinkerData,
    [content]
  );

  const dimensions = DIMENSIONS[ratio][imageSize];

  if (!content || !Object.keys(content).length) {
    // TODO use Media, when perso content is loading (placeholder)
    return (
      <li className={styles.contentRowTemplateItem} data-ratio={`${ratio}${imageSize}`}>
        <CardConnected
          imageSize={imageSize}
          ratio={ratio}
          titleDisplayMode={titleDisplayMode}
          dimensions={dimensions}
        />
      </li>
    );
  }

  const { startTime, endTime } = content;

  const isDescriptionVisible =
    titleDisplayMode === TitleDisplayMode.Description || titleDisplayMode === TitleDisplayMode.All;

  const publicConfig = getPublicConfig();

  const URLImage = content.URLImage;
  const { URLImageForDarkMode = URLImage, URLImageForLightMode = URLImage, imageSpecificities } = content;

  const isTitleVisible = titleDisplayMode !== TitleDisplayMode.None;
  const title = isTitleVisible ? content.title : '';
  const subtitle = isTitleVisible && titleDisplayMode !== TitleDisplayMode.TitleOnly ? content.subtitle : '';
  const description = isDescriptionVisible ? content.description : '';
  const image = { default: isDark ? URLImageForDarkMode : URLImageForLightMode };
  const logoChannel = { default: content.URLLogoChannel ?? '' };
  const onGoing = startTime ? { startTime, endTime } : undefined;
  const isArticle = content.type === publicConfig.TEMPLATE.CREATIVE_MEDIA;

  const disabledStyle = {
    disabled: !content?.onClick?.URLPage,
    opacityOnPoster: false,
  } satisfies CardProps['disabledStyle'];

  const ariaLabel = isTop10 ? `Top ${content.rank} ${content.title}` : content.title;

  const cardProps = {
    ariaLabel,
    description,
    disabledStyle,
    displayName: content.onClick?.displayName,
    image,
    imageSize,
    isBackgroundTransparent,
    imageSpecificities,
    isArticle,
    isLogoTyped: content.isLogoTyped,
    logoChannel,
    onGoing,
    ratio,
    subtitle,
    title,
    titleDisplayMode,
    userProgress: content.userProgress,
    altImage: content.altImage,
    altLogoChannel: content.altLogoChannel,
    isInOffer: content.isInOffer,
    dimensions,
    sticker: getHodorSticker(content.stickers, isFeatUpcomingHodorSticker),
  } satisfies CardConnectedProps;

  const itemClassnames = cx('contentRowTemplateItem', {
    'contentRowTemplateItem--carousel': ratio === Ratio.Ratio166 && !isDescriptionVisible,
    'contentRowTemplateItem--immersive': isFromImmersive,
    'contentRowTemplateItem--logoTyped': titleDisplayMode === TitleDisplayMode.LogoTyped,
    'contentRowTemplateItem--logoTyped--top10': titleDisplayMode === TitleDisplayMode.LogoTyped && isTop10,
    'contentRowTemplateItem--detailV5': isFromDetail,
    'contentRowTemplateItem--top10': isTop10,
  });

  // Clickable card
  if (content.onClick?.URLPage) {
    return (
      <li className={itemClassnames} data-ratio={`${ratio}${imageSize}`}>
        <Linker
          className={cx('contentRowTemplateItem__itemLink', {
            'contentRowTemplateItem__itemLink--top10': isTop10,
          })}
          data={linkerData}
          title={ariaLabel}
          ariaLabel={ariaLabel}
        >
          {isTop10 && !!content.rank && <RankItemStandard rank={content.rank} />}
          {imageSpecificities === 'isLogo' ? (
            <ButtonLogo
              as="div"
              logo={
                <ThumborMediaImage url={image.default} dimensions={dimensions} alt={content.altImage} height="auto" />
              }
            />
          ) : (
            <div className={styles['contentRowTemplateItem__itemLink--top10__media']}>
              <CardConnected
                {...cardProps}
                cardType={strateMode}
                contentID={content.contentID}
                isRemovableItem={isRemovableItem}
                lastDays={content.lastDays}
                listType={listType}
                onClickRemove={onClickRemove}
              />
            </div>
          )}
        </Linker>
      </li>
    );
  }

  // Not clickable card
  return (
    <li className={styles.contentRowTemplateItem} data-ratio={`${ratio}${imageSize}`}>
      <CardConnected
        {...cardProps}
        cardType={strateMode}
        contentID={content.contentID}
        isRemovableItem={isRemovableItem}
        lastDays={content.lastDays}
        listType={listType}
        onClickRemove={onClickRemove}
        dataTvFocusable
      />
    </li>
  );
}
